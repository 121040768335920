import { SectionProps } from "../shared/SectionProps";
import '../..//css/body/profile.css';

export const Profile = (props: SectionProps) => {
   return (
      <div id={props.id} className="section profile-container py-5 px-5">
         <h2 className="py-5 text-center">Czym Się <span className="gradient-color">Zajmujemy</span>?</h2>
         <div className="row m-0 p-0 justify-content-around">
            <div className="profile-item col-12 col-lg-4 py-4 my-4 d-flex justify-content-start justify-content-lg-center align-items-start">
               <i className="bi-water bullet-icon ms-3"></i>
               <span className="ms-3 ms-md-5 me-3 my-3">
                  <h5>Oczyszczalnie Ścieków</h5>
                  <hr className="profile-item-hr" />
                  <b>Projektujemy i budujemy</b> oczyszczalnie ścieków, które stanowią <b>ważny</b> element infrastruktury ochrony środowiska.
               </span>
            </div>
            <div className="profile-item col-12 col-lg-4 py-4 my-4 d-flex justify-content-start justify-content-lg-center align-items-start">
               <i className="bi-moisture bullet-icon ms-3"></i>
               <span className="ms-3 ms-md-5 me-3 my-3">
                  <h5>Ujęcia Wody</h5>
                  <hr className="profile-item-hr" />
                  Wykonujemy ujęcia wody w pełnym zakresie <b>uzdatniania wody</b> do picia i na potrzeby gospodarcze lub przemysłowe.
               </span>            
            </div>
            <div className="profile-item col-12 col-lg-4 py-4 my-4 d-flex justify-content-start justify-content-lg-center align-items-start">
               <i className="bi-wrench-adjustable bullet-icon ms-3"></i>
               <span className="ms-3 ms-md-5 me-3 my-3">
                  <h5>Sieci Wodociągowo-Kanalizacyjne</h5>
                  <hr className="profile-item-hr" />
                  Projektujemy i wykonujemy sieci wodociągowe i kanalizacyjne dla <b>urzędów miast i gmin</b>.
               </span>
            </div>
         </div>
         <div className="row m-0 p-0 pb-4 justify-content-center">
            <div className="profile-item col-12 col-lg-4 py-4 my-4 me-lg-5 d-flex justify-content-start justify-content-lg-center align-items-start">
               <i className="bi-sun-fill bullet-icon ms-3"></i>
               <span className="ms-3 ms-md-5 me-3 my-3">
                  <h5>Instalacje Fotowoltaiczne</h5>
                  <hr className="profile-item-hr" />
                  Korzystając z <b>najnowszych technologii</b>, doradzamy i pomagamy naszym Klientom znacząco <b>obniżyć koszty</b> pozyskiwania <b>energii</b> elektrycznej i cieplnej. Preferujemy wykorzystanie odnawialnych źródeł <b>zielonej energii</b> w celu ochrony klimatu i Twoich <b>finansów</b>.
               </span>
            </div>
            <div className="profile-item col-12 col-lg-4 py-4 my-4 ms-lg-4 d-flex justify-content-start justify-content-lg-center align-items-start">
               <i className="bi-thermometer-sun bullet-icon ms-3"></i>
               <span className="ms-3 ms-md-5 me-3 my-3">
                  <h5>Pompy Ciepła</h5>
                  <hr className="profile-item-hr" />
                  Projektujemy i wykonujemy <b>instalacje</b> pomp ciepła, dbając o Twój <b>komfort</b> użytkowania tych urządzeń i <b>efektywność</b> energetyczną inwestycji.
               </span>
            </div>
         </div>
      </div>
   );
}