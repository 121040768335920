import '.././css/navbar.css';
import {useEffect, useState} from "react";
import {SectionProps} from './shared/SectionProps';
import {ScrollingService} from './util/ScrollingService';

interface NavbarProps extends SectionProps {
   sectionIds: string[]
}

export const Navbar = (props: NavbarProps) => {

   const STARTING_SECTION_ID: string = props.sectionIds[0];
   const NAVIGATION_NAMES: string[] = ["Główna", "Profil", "Cechy", "Kariera", "Kontakt"];

   const [currentSection, setCurrentSection] = useState(STARTING_SECTION_ID);
   const [breakingPoints, setBreakingPoints] = useState<Map<string, number>>(new Map());

   useEffect(() => {
      calculateBreakingPoints();
      window.addEventListener('resize', calculateBreakingPoints);
   }, [])

   useEffect(() => {
      window.addEventListener('scroll', () => {

         const scrollY = window.scrollY;
         const breakingPointsEntries = Array.from(breakingPoints.entries());

         breakingPointsEntries.forEach((value, index) => {
            const currentKey = value[0];
            const currentValue = value[1];

            if (index === breakingPointsEntries.length - 1) {
               if (currentKey !== currentSection && scrollY >= currentValue - 100) {
                  setCurrentSection(currentKey);
                  return;
               }
            } else {
               const nextValue = breakingPointsEntries[index+1][1];

               if (currentKey !== currentSection && scrollY >= currentValue - 100 && scrollY < nextValue - 100) {
                  setCurrentSection(currentKey);
                  return;
               }
            }
         })
      });
   })

   const calculateBreakingPoints = () => {
      const navItems = document.querySelectorAll(".section");

         let breakingPointMap = new Map<string, number>();
         let lastBreakingPoint: number = 100;

         for (let i=0; i<navItems.length; i++) {
            if (i === 0) {
               breakingPointMap.set(navItems[i].id, lastBreakingPoint);
            } else {
               const breakingPoint = lastBreakingPoint + navItems[i-1].getBoundingClientRect().height - 50;
               breakingPointMap.set(navItems[i].id, breakingPoint);
               lastBreakingPoint = breakingPoint;
            }
         }

         setBreakingPoints(breakingPointMap);
   }

   return (
      <div id={props.id} className="navbar-container justify-contant-between">
         <nav className="navbar fixed-top navbar-expand-lg navbar-custom navbar-dark bg-dark">
            <span className="navbar-brand ms-4 company-logo" onClick={() => ScrollingService.scrollToElement(STARTING_SECTION_ID)}>
               <img src="img/droplet.svg" alt="aqua-sp-zoo" width="41" height="45" />
            </span>
            <p className="m-0 p-0 mx-3 navbar-title">
               {currentSection === STARTING_SECTION_ID ? "" : "AQUA Sp. z o.o."}
            </p>
            <button className="navbar-toggler me-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Menu">
               <span className="navbar-toggler-icon"></span>
            </button>
            <div id="navbarNavAltMarkup" className="collapse navbar-collapse justify-content-end me-4">
               <div id="menuItemki" className="navbar-nav text-center">
                  {
                     props.sectionIds.map((sectionId, it) => {
                        return (
                           <li key={`${sectionId}_${it}`} className={`nav-item nav-link mx-1 ${currentSection === sectionId ? "nav-link-active" : ""}`} onClick={() => ScrollingService.scrollToElement(sectionId)}>
                              {NAVIGATION_NAMES[it]}
                           </li>
                        )}
                     )
                  }
               </div>
            </div>
         </nav>
      </div>
   );
}