import {Footer} from './component/Footer';
import {Navbar} from './component/Navbar';
import {Landing} from "./component/body/Landing";
import {Contact} from "./component/body/Contact";
import './css/app.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import {Features} from "./component/body/Features";
import {CookieConsent,} from "./component/CookieConsent";
import {useState} from "react";
import {PrivacyPolicy} from "./component/body/PrivacyPolicy";
import {Profile} from "./component/body/Profile";
import {Career} from "./component/body/Career";

export const App = () => {

   const NAVBAR_ID: string = "navigation";
   const LANDING_ID: string = "landing";
   const PROFILE_ID: string = "profile";
   const FEATURES_ID: string = "features";
   const CAREER_ID: string = "career";
   const ABOUT_ID: string = "about";
   const FOOTER_ID: string = "footer";
   const SECTION_IDS: string[] = [LANDING_ID, PROFILE_ID, FEATURES_ID, CAREER_ID, ABOUT_ID];

   const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState(false);

    return (
        <div>
            <Navbar id={NAVBAR_ID}
                  sectionIds={SECTION_IDS} />
            <Landing id={LANDING_ID}
                   targetButtonId={PROFILE_ID} />
            <Profile id={PROFILE_ID} />
            <Features id={FEATURES_ID} />
            <Career id={CAREER_ID} />
            <Contact id={ABOUT_ID} />
            <Footer id={FOOTER_ID}
                  privacyPolicyVisible={privacyPolicyVisible}
                  setPrivacyPolicyVisible={setPrivacyPolicyVisible} />
            <PrivacyPolicy privacyPolicyVisible={privacyPolicyVisible}
                         setPrivacyPolicyVisible={setPrivacyPolicyVisible} />
            <CookieConsent privacyPolicyVisible={privacyPolicyVisible}
                         setPrivacyPolicyVisible={setPrivacyPolicyVisible} />
        </div>
    );
}