import {useEffect, useState} from "react"
import '.././css/cookie-consent.css';
import {PrivacyPolicyProps} from "./shared/PrivacyPolicyProps";

export const CookieConsent = (props: PrivacyPolicyProps) => {

   const COOKIE_CONSENT_STORAGE_ID = "aqua-lublin-cookies";

   const [cookieBannerVisible, setCookieBannerVisible] = useState(false);

   useEffect(() => {
      checkCookieConsent();
   }, [cookieBannerVisible])

   const checkCookieConsent = () => {
      const cookieConsentState = localStorage.getItem(COOKIE_CONSENT_STORAGE_ID);

      if (cookieConsentState === "true") {
         setCookieBannerVisible(false);
      } else {
         setCookieBannerVisible(true);
      }
   }

   const saveCookieConsent = () => {
      localStorage.setItem(COOKIE_CONSENT_STORAGE_ID, "true");
      setCookieBannerVisible(false);
   }

   if (cookieBannerVisible) {
      return (
         <div className="conatiner cookie-consnet-container d-flex justify-content-between py-4 m-0 align-items-center">
            <div className="mx-4">
               Używamy plików <b>cookies</b>. Przeglądając stronę akceptujesz naszą <span className="cookie-privacy-policy-link" onClick={() => props.setPrivacyPolicyVisible(true)}>Politykę Prywatności</span>.
            </div>
            <div className="me-4">
               <button className="btn btn-primary" onClick={saveCookieConsent}>
                  <i className="bi-check-lg pe-2 cookie-privacy-policy-acknowledge-icon"></i>{' '}
                  Rozumiem
               </button>
            </div>
         </div>
      );
   } else {
      return (<></>);
   }
}