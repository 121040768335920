import { SectionProps } from './shared/SectionProps';
import '.././css/footer.css';
import { PrivacyPolicyProps } from './shared/PrivacyPolicyProps';

interface FooterProps extends SectionProps, PrivacyPolicyProps {}

export const Footer = (props: FooterProps) => {
   return (
      <div id={props.id}>
         <div className="d-flex footer justify-content-center align-items-center align-self-center py-5 pt-lg-3">
            <span className="py-3">
               <img src="img/droplet.svg" alt="aqua-sp-zoo" className="footer-logo" />
            </span>
            <span className="py-3 ms-3 ms-md-5">
               <span>
                  <a href="https://maps.app.goo.gl/a7SUom2f3ihz7N7F6" target="_blank" rel="noreferrer" className="footer-element">
                     <i className="bi bi-geo-alt-fill footer-icon"></i>
                     <span className="m-0 p-0 ms-2">21-008 Tomaszowice Kol. 36A</span>
                  </a>
               </span>
               <br className="mb-2" />
               <span className="footer-element">
                  <i className="bi bi-telephone-fill footer-icon"></i>
                  <span className="m-0 p-0 ms-2">81 502 92 18</span>
               </span>
               <br className="mb-2" />
               <span className="footer-element pointer" onClick={() => props.setPrivacyPolicyVisible(true)}>
                  <i className="bi bi-file-text footer-icon"></i>
                  <span className="m-0 p-0 ms-2">Polityka Prywatności</span>
               </span>
            </span>
         </div>
      </div>
   );
}