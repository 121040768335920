import '../..//css/body/landing.css';
import {SectionProps} from '../shared/SectionProps';
import {ScrollingService} from '../util/ScrollingService';

interface LandingProps extends SectionProps {
   targetButtonId: string;
}

export const Landing = (props: LandingProps) => {
   return (
      <div id={props.id} className="section landing-conatiner d-flex flex-wrap flex-lg-nowrap justify-content-around align-items-center py-5 mx-3 text-center">
         <div className="main-text-conatiner center-text-below-lg py-5">
            <h1 className="fw-bold mb-5 gradient-color">AQUA Sp. z o.o.</h1>
            <p className="title-text fst-italic pb-4">Nasza misja to dostarczanie <b>innowacyjnych, efektywnych i ekologicznych rozwiązań</b>, które odpowiadają na wyzwania związane z ochroną środowiska i zapewnieniem <b>czystej wody i energii</b> dla społeczności i przemysłu.</p>
            <button className="btn btn-lg btn-landing mt-3" onClick={() => ScrollingService.scrollToElement(props.targetButtonId)}>
               <div className="d-flex flex-row justify-content-center align-items-center">
                   <i className="icon bi-book btn-landing-icon pe-3"></i>
                   <span>Dowiedz Się Więcej ...</span>
               </div>
            </button>
         </div>
         <div className="cover-img-conatiner mx-0 px-0 pb-5">
            <img src="img/plumbing.png" alt="aqua-sp-zoo" className="cover-img" />
         </div>
      </div>
   );
}