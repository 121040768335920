import '../..//css/body/career.css';
import {SectionProps} from "../shared/SectionProps";

export const Career = (props: SectionProps) => {
   return (
      <div id={props.id} className="section career-container py-5">
          <h2 className="py-5 text-center"><span className="gradient-color">Dołącz</span> do Nas!</h2>
          <div className="d-flex flex-row flex-wrap justify-content-center pb-5 mx-4 mx-md-5">
              <img src="img/construction.png" alt="aqua-sp-zoo" className="construction-img order-last order-md-first pt-3 pt-md-0 pe-0 pe-md-3"/>
              <div className="vr d-none d-xl-block mx-5"></div>
              <div className="align-self-center text-center pb-0">
                  <p className="career-text text-center mx-3 pt-4 pb-2">
                      Aktualnie poszukujemy <b>pracowników budowlanych</b>.
                  </p>
                  <div className="pt-4 pb-5">
                      <a href="mailto:info@aqua.lublin.pl" className="btn career-button py-3">
                         <span className="contact-call-phone-text">
                            <i className="bi bi-envelope-at-fill me-4"></i><b>Wyślij CV!</b>
                         </span>
                      </a>
                  </div>
              </div>
          </div>
      </div>
   );
}