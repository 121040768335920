import { Dialog } from 'primereact/dialog';
import { PrivacyPolicyProps } from '../shared/PrivacyPolicyProps';

export const PrivacyPolicy = (props: PrivacyPolicyProps) => {
   return (
      <Dialog header="Polityka Prywatności" visible={props.privacyPolicyVisible} onHide={() => props.setPrivacyPolicyVisible(false)} 
              style={{ width: '70vw' }} draggable={false} resizable={false}>
         <div id="pol-pryw" className="containder-fluid">
            <strong>Zgodnie z art. 13 ust. 1 Ogólnego Rozporządzenia o Ochronie Danych (RODO) informujemy, że:</strong><br />
            1. administratorem Państwa danych osobowych jest Aqua Lublin Sp. z o.o. z siedzibą w Tomaszowicach-Kolonii 36A, 21-008 Tomaszowice, NIP: 713-020-73-06<br />
            2. w sprawach przetwarzania Państwa danych osobowych proszę kontaktować się z administratorem za pośrednictwem poczty elektronicznej: rodo [@] aqua.lublin.pl<br />
            3. administrator będzie przetwarzał Państwa dane osobowe na podstawie art. 6 ust. 1 lit. b) RODO, tj. przetwarzanie jest niezbędne w celu wykonania umowy, której stroną jest osoba, której dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy;<br />
            4. dane osobowe mogą być udostępnione innym uprawnionym podmiotom, na podstawie przepisów prawa, a także na rzecz podmiotów, z którymi<br />
            administrator zawarł umowę powierzenia przetwarzania danych w związku z realizacją usług na rzecz administratora (np. kancelarią prawną, dostawcą oprogramowania, zewnętrznym audytorem);<br />
            5. administrator nie zamierza przekazywać Państwa danych osobowych do państwa trzeciego lub organizacji międzynarodowej;<br />
            6.mają Państwo prawo uzyskać kopię swoich danych osobowych w siedzibie administratora.<br />
            <br />
            <strong>Dodatkowo zgodnie z art. 13 ust. 2 RODO informujemy, że:</strong><br />
            1. Państwa dane osobowe będą przechowywane do momentu upływu okresu przedawnienia wynikającego z ustawy z dnia 23 kwietnia 1964 r. Kodeks cywilny;<br />
            2. przysługuje Państwu prawo dostępu do treści swoich danych, ich sprostowania lub ograniczenia przetwarzania, a także prawo do wniesienia sprzeciwu wobec przetwarzania, prawo do przeniesienia danych oraz prawo do wniesienia skargi do organu nadzorczego;<br />
            3. podanie danych osobowych jest dobrowolne, jednakże niezbędne do zawarcia umowy. Konsekwencją niepodania danych osobowych będzie brak realizacji umowy;<br />
            4. administrator nie podejmuje decyzji w sposób zautomatyzowany w oparciu o Państwa dane osobowe.
            <p><strong><u>Polityka plików cookies</u></strong><br />
            <br />
            <strong>Czym są pliki „cookies”?</strong><br />
            Poprzez pliki „cookies” należy rozumieć dane informatyczne, w szczególności pliki tekstowe, przechowywane w urządzeniach końcowych użytkowników przeznaczone do korzystania ze stron internetowych. Pliki te pozwalają rozpoznać urządzenie użytkownika i odpowiednio wyświetlić stronę internetową dostosowaną do jego indywidualnych preferencji. „Cookies” zazwyczaj zawierają nazwę strony internetowej z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</p>
            <p><strong>Do czego używamy plików „cookies”?</strong><br />
            Pliki „cookies” używane są w celu dostosowania zawartości stron internetowych do preferencji użytkownika oraz optymalizacji korzystania ze stron internetowych. Używane są również w celu tworzenia anonimowych, zagregowanych statystyk, które pomagają zrozumieć w jaki sposób użytkownik korzysta ze stron internetowych co umożliwia ulepszanie ich struktury i zawartości, z wyłączeniem personalnej identyfikacji użytkownika. Gromadzimy także udostępnianie przez użytkowników dane dotyczące: remarketingu, raportów wyświetleń w sieci reklamowej Google, demografii (wieku, płci) i zainteresowań oraz zintegrowanych usług, które wymagają od Google Analytics zbierania danych za pośrednictwem plików cookie dotyczących reklam i anonimowych identyfikatorów. Robimy to, w celu lepszego poznania naszych użytkowników. Używamy do analiz Google Analytics.</p>
            <p><strong>Jakich plików „cookies” używamy?</strong><br />
            Stosowane są, co do zasady, dwa rodzaje plików „cookies” – „sesyjne” oraz „stałe”. Pierwsze z nich są plikami tymczasowymi, które pozostają na urządzeniu użytkownika, aż do wylogowania ze strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki pozostają na urządzeniu użytkownika przez czas określony w parametrach plików „cookies” albo do momentu ich ręcznego usunięcia przez użytkownika.<br />
            Pliki „cookies” wykorzystywane przez partnerów operatora strony internetowej podlegają ich własnej polityce prywatności.</p>
            <p>Wyróżnić można szczegółowy podział cookies, ze względu na:</p>
            <p><strong>1. Ze względu na niezbędność do realizacji usługi:</strong></p>
            <ul>
            <li><strong>Niezbędne</strong> są absolutnie niezbędne do prawidłowego funkcjonowania witryny lub funkcjonalności z których użytkownik chce skorzystać</li>
            <li><strong>Funkcjonalne</strong> Są ważne dla działania serwisu:
            <ul>
            <li>służą wzbogaceniu funkcjonalności serwisu, bez nich serwis będzie działał poprawnie, jednak nie będzie dostosowany do preferencji użytkownika,</li>
            <li>służą zapewnieniu wysokiego poziomu funkcjonalności serwisu, bez ustawień zapisanych w plikach cookies może obniżyć się poziom funkcjonalności witryny, ale nie powinna uniemożliwić zupełnego korzystania z niej,</li>
            <li>służą bardzo ważnym funkcjonalnościom serwisu, ich zablokowanie spowoduje, że wybrane funkcje nie będą działać prawidłowo.</li>
            </ul>
            </li>
            <li><strong>Biznesowe</strong> Umożliwiają realizację modelu biznesowego w oparciu o który udostępniona jest witryna, ich zablokowanie nie spowoduje niedostępności całości funkcjonalności serwisu ale może obniżyć poziom świadczenia usługi ze względu na brak możliwości realizacji przez właściciela witryny przychodów subsydiujących działanie serwisu. Do tej kategorii należą np. cookies reklamowe.</li>
            </ul>
            <p><strong> 2. Ze względu na czas przez jaki cookies będą umieszczone w urządzeniu końcowym użytkownika</strong></p>
            <p><strong>a) Cookies tymczasowe (session cookies)</strong><br />
            cookies umieszczone na czas korzystania z przeglądarki (sesji), zostają wykasowane po jej zamknięciu</p>
            <p><strong>b) Cookies stałe (persistent cookies)</strong><br />
            nie są kasowane po zamknięciu przeglądarki i pozostają w urządzeniu użytkownika na określony czas lub bez okresu ważności w zależności od ustawień właściciela witryny</p>
            <p><strong>3. Ze względu na pochodzenie – administratora serwisu, który zarządza cookies</strong></p>
            <p><strong>a) Cookies własne (first party cookies)</strong></p>
            <p>cookies umieszczone bezpośrednio przez właściciela witryny jaka została odwiedzona</p>
            <p><strong>b) Cookies zewnętrzne (third-party cookies)</strong></p>
            <p>cookies umieszczone przez zewnętrzne podmioty, których komponenty stron zostały wywołane przez właściciela witryny</p>
            <p><strong>Uwaga:</strong> cookies mogą być wywołane przez administratora za pomocą skryptów, komponentów, które znajdują się na serwerach partnera, umiejscowionych w innej lokalizacji innym kraju lub nawet zupełnie innym systemie prawnym. W przypadku wywołania przez administratora witryny komponentów serwisu pochodzących spoza systemu administratora mogą obowiązywać inne standardowe zasady polityki cookies niż polityka prywatności / cookies administratora witryny.</p>
            <p><strong>4) Ze względu na cel jakiemu służą</strong></p>
            <ul>
            <li><strong>Konfiguracji serwisu</strong> umożliwiają ustawienia funkcji i usług w serwisie. Bezpieczeństwo i niezawodność serwisu umożliwiają weryfikację autentyczności oraz optymalizację wydajności serwisu.</li>
            <li><strong>Uwierzytelnianie</strong> umożliwiają informowanie gdy użytkownik jest zalogowany, dzięki czemu witryna może pokazywać odpowiednie informacje i funkcje.</li>
            <li><strong>Stan sesji</strong> umożliwiają zapisywanie informacji o tym, jak użytkownicy korzystają z witryny. Mogą one dotyczyć najczęściej odwiedzanych stron lub ewentualnych komunikatów o błędach wyświetlanych na niektórych stronach. Pliki cookies służące do zapisywania tzw. „stanu sesji” pomagają ulepszać usługi i zwiększać komfort przeglądania stron.</li>
            <li><strong>Procesy</strong> umożliwiają sprawne działanie samej witryny oraz dostępnych na niej funkcji</li>
            <li><strong>Reklamy</strong> umożliwiają wyświetlać reklamy, które są bardziej interesujące dla użytkowników, a jednocześnie bardziej wartościowe dla wydawców i reklamodawców, personalizować reklamy, mogą być używane również do wyświetlania reklam poza stronami witryny (domeny)</li>
            <li><strong>Lokalizacja</strong> umożliwiają dostosowanie wyświetlanych informacji do lokalizacji użytkownika.</li>
            <li><strong>Analizy i badania, audyt oglądalności</strong> umożliwiają właścicielom witryn lepiej zrozumieć preferencje ich użytkowników i poprzez analizę ulepszać i rozwijać produkty i usługi. Zazwyczaj właściciel witryny lub firma badawcza zbiera anonimowo informacje i przetwarza dane na temat trendów bez identyfikowania danych osobowych poszczególnych użytkowników.</li>
            </ul>
            <p><strong>5. Ze względu na ingerencję w prywatność użytkownika</strong></p>
            <p><strong>a) Nieszkodliwe:</strong></p>
            <p>Obejmuje cookies:</p>
            <ul>
            <li>niezbędne do poprawnego działania witryny</li>
            <li>potrzebne do umożliwienia działania funkcjonalności witryny, jednak ich działanie nie ma nic wspólnego z śledzeniem użytkownika</li>
            </ul>
            <p><strong>b) Badające:</strong></p>
            <p>wykorzystywane do śledzenia użytkowników jednak nie obejmują informacji pozwalających zidentyfikować danych konkretnego użytkownika</p>
            <p><strong>Czy pliki „cookies” zawierają dane osobowe?</strong><br />
            Dane osobowe gromadzone przy użyciu plików „cookies” mogą być zbierane wyłącznie w celu wykonywania określonych funkcji na rzecz użytkownika. Takie dane są zaszyfrowane w sposób uniemożliwiający dostęp do nich osobom nieuprawnionym.</p>
            <p><strong>Usuwanie plików „cookies”</strong><br />
            Standardowo oprogramowanie służące do przeglądania stron internetowych domyślnie dopuszcza umieszczanie plików „cookies” na urządzeniu końcowym. Ustawienia te mogą zostać zmienione w taki sposób, aby blokować automatyczną obsługę plików „cookies” w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym przesłaniu na urządzenie użytkownika.</p>
            <p>Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).<br />
            Ograniczenie stosowania plików „cookies”, może wpłynąć na niektóre funkcjonalności dostępne na stronie internetowej.</p>
        </div>
      </Dialog>
   );
}