import '../..//css/body/features.css';
import { SectionProps } from "../shared/SectionProps";

export const Features = (props: SectionProps) => {
   return (
      <div id={props.id} className="section features-container py-5">
         <h2 className="py-5 text-center">Co Nas <span className="gradient-color">Wyróżnia</span>?</h2>
         <div className="row m-0 p-0 text-center">
            <div className="col-12 col-lg-4 py-4 d-flex justify-content-center ">
               <div className="card custom-card">
                  <div className="card-body">
                     <div className="pt-5"></div>
                     <i className="bi bi-rocket-takeoff-fill card-icon py-2 px-2"></i>
                     <h4 className="card-title custom-card-title pt-4 pb-2">Innowacyjność</h4>
                     <p className="card-text">Nieustannie poszukujemy <b>najnowszych technologii</b>, aby zapewnić naszym klientom optymalne i efektywne rozwiązania.</p>
                  </div>
               </div>
            </div>
            <div className="col col-12 col-lg-4 py-4 d-flex justify-content-center">
               <div className="card custom-card">
                  <div className="card-body">
                     <div className="pt-5"></div>
                     <i className="bi bi-ui-checks card-icon py-2 px-2"></i>
                     <h4 className="card-title custom-card-title pt-4 pb-2">Elastyczność</h4>
                     <p className="card-text">Nasze podejście opiera się na <b>partnerstwie</b> i <b>współpracy</b> z klientami na każdym etapie procesu celem doboru najlepszego możliwego rozwiązania.</p>
                  </div>
               </div>
            </div>
            <div className="col col-12 col-lg-4 py-4 d-flex justify-content-center">
               <div className="card custom-card">
                  <div className="card-body">
                     <div className="pt-5"></div>
                     <i className="bi bi-people-fill card-icon py-2 px-2"></i>
                     <h4 className="card-title custom-card-title pt-4 pb-2">Doświadczenie</h4>
                     <p className="card-text">Nasz zespół składa się z profesjonalistów, którzy posiadają <b>bogate doświadczenie</b> zdobyte w realizacji różnorodnych projektów.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}